import React, { useEffect } from 'react'
import logo from '../assets/logo.jpg';
import meme_1 from '../assets/meme_1.jpeg';
import meme_2 from '../assets/meme_2.jpeg';
import video_1 from '../assets/video_1.mp4';
import video_2 from '../assets/video_2.mp4';
import video_3 from '../assets/video_3.mp4';
import top_1 from '../assets/top_1.jpeg';
import top_2 from '../assets/top_2.jpeg';
import icon_6 from '../assets/icon_6.png';
import icon_3 from '../assets/icon_3.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { TwitterTweetEmbed } from 'react-twitter-embed';

export default function Home() {

    useEffect(() => {
        Aos.init({
            duration: 800,
        });
        Aos.refresh();
    }, [])

    return (
        <div className="font-montserat bg-gradient overflow-hidden text-black-100">

            {/* top section */}

            <div className="lg:px-20 px-5 py-12">
                <div className='container mx-auto'>

                    <div className="flex flex-col justify-center items-center mx-auto gap-y-12 lg:py-8">
                        <div className="lg:w-7/12">

                            <img data-aos='zoom-in' src={logo} alt='logo' className='flex mx-auto rounded-full lg:w-1/2 border-8 border-pink-100 shadow-xl shadow-pink-200'></img>

                            <h1 data-aos='fade-up' className='font-bold lg:text-5xl text-3xl text-center lg:my-12 my-6'>
                                TOKENOMICS
                            </h1>

                            <div className="lg:flex hidden items-center justify-center lg:gap-x-6 gap-x-4">

                                <div data-aos='fade-right' className='flex flex-col items-center text-center gap-y-6'>
                                    <p className='text-red-100 lg:text-lg font-bold'>Token Name:</p>
                                    <p className='text-red-100 lg:text-lg font-bold'>Supply:</p>
                                    <p className='text-red-100 lg:text-lg font-bold'>Tax:</p>
                                    <p className='text-red-100 lg:text-lg font-bold'>Liquidity:</p>
                                </div>

                                <div data-aos='fade-left' className='flex flex-col items-start gap-y-6'>
                                    <p className='lg:text-lg font-bold'>$BIGFOOT</p>
                                    <p className='lg:text-lg font-bold'>1000000</p>
                                    <p className='lg:text-lg font-bold'>0%</p>
                                    <p className='lg:text-lg font-bold'>Burnt</p>
                                </div>
                            </div>

                            <div className='lg:hidden block text-center space-y-4'>

                                <p className='font-semibold'>
                                    <span className='text-red-100'>Token Name:</span> $BIGFOOT
                                </p>

                                <p className='font-semibold'>
                                    <span className='text-red-100'>Supply:</span> 1000000
                                </p>

                                <p className='font-semibold'>
                                    <span className='text-red-100'>Tax:</span> 0%
                                </p>

                                <div>
                                    <p className='text-red-100 font-semibold'>Liquidity</p>
                                    <p className='font-semibold'>Burnt</p>
                                </div>

                            </div>

                            <div className="flex items-center justify-center gap-8 flex-wrap mt-6">
                                <a href='https://t.me/Bigfoot_Coin'>
                                    <img src={icon_3} alt='Telegram'></img>
                                </a>
                                <a href='https://twitter.com/BIGFOOT_SOL'>
                                    <img src={icon_6} alt='Twitter'></img>
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* about us */}

                    <div className="lg:flex block justify-center items-start mx-auto gap-x-24 lg:py-20 py-6 lg:space-y-0 space-y-8">

                        <div data-aos='slide-right' className='lg:w-1/2 space-y-6'>
                            <h2 className='font-bold lg:text-5xl text-3xl lg:my-12 my-6 uppercase'>
                                about us
                            </h2>

                            <p>
                                Beef between the famous artist Nicki Minaj and Megan The Stallion has been getting very heated lately, when Megan dissed Nicki by calling out her husband as a sex offender making her take action immediately on Instagram Live with the quote "Bad bitch she like 6 foot I call her Big Foot"
                            </p>
                            <p>
                                That sparked an immediate viral sensation, trending all over twitter with memes of Megan as Bigfoot.
                            </p>
                        </div>

                        <div data-aos='slide-left' className='lg:w-1/2'>
                            <div className='grid lg:grid-cols-2 grid-cols-1 grid-rows-2 items-center gap-y-6 justify-between'>
                                <div className=''>
                                    <img src={meme_1} alt='meme' className='lg:w-72 lg:h-72 object-cover rounded-tl-4xl rounded-br-4xl border-8 border-pink-300 rounded-sm'></img>
                                </div>

                                <div className=''>
                                    <img src={meme_2} alt='meme' className='lg:w-72 w-full h-72 object-cover rounded-tl-4xl rounded-br-4xl border-8 border-pink-300 rounded-sm'></img>
                                </div>

                                <div className=''>
                                    <img src={top_1} alt='meme' className='lg:w-72 rounded-tl-4xl rounded-br-4xl border-8 border-pink-300 rounded-sm'></img>
                                </div>

                                <div className=''>
                                    <img src={top_2} alt='meme' className='lg:w-72 rounded-tl-4xl rounded-br-4xl border-8 border-pink-300 rounded-sm'></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='grid lg:grid-cols-3 grid-cols-1 items-start justify-between gap-8'>
                        <TwitterTweetEmbed tweetId="1751481915951976776" />
                        <TwitterTweetEmbed tweetId="1751589583177843068" />
                        <TwitterTweetEmbed tweetId="1751559631803957526" />
                    </div>

                    <div className='grid lg:grid-cols-3 grid-cols-1 items-start justify-between gap-8 lg:py-20'>
                        <div className=''>
                            <video src={video_1} controls className='rounded-2xl'></video>
                        </div>

                        <div className=''>
                            <video src={video_2} controls className='rounded-2xl'></video>
                        </div>

                        <div className=''>
                            <video src={video_3} controls className='rounded-2xl'></video>
                        </div>
                    </div>

                    <p className='text-center mt-6'>© {new Date().getFullYear()} Megan thee Stallion - All rights reserved.</p>

                </div>
            </div>

        </div >
    )
}
